import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { _ROUTES, _LOGIN_ROUTE, _TNC_ROUTE } from './../../config/page-route';
import { PageSettings } from './../../config/page-settings.js';
import { AccessRight, WebUrl } from "../../util/Constant";
import { connect } from "react-redux";
import { Redirect } from 'react-router';

/// <summary>
/// Author: -
/// </summary>
const compareRoutes = (routePath, path) => {
    const splitRoutePath = routePath.split('/').slice(1);
    const splitPath = path.split('/').slice(1);

    splitRoutePath.map((item, index) => {
        if (item.charAt(0) === ':') {
            splitRoutePath.splice(index, 1);

            if (splitPath[index] !== undefined) {
                splitPath.splice(index, 1);
            }
        }
    });

    return splitRoutePath.join('/') === splitPath.join('/');
}

const setTitle = (path, routeArray) => {
    var pageTitle = process.env.REACT_APP_NAME;

    for (var i = 0; i < routeArray.length; i++) {
        if (compareRoutes(routeArray[i].path, path)) {
            pageTitle = `${routeArray[i].title} | ` + pageTitle;
        }
    }

    document.title = pageTitle;
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: _ROUTES
        };
    }

    /// <summary>
    /// Author: -
    /// </summary>
    PrivateRoute(index, routeData) {
        return (
            <Route
                key={index}
                exact={routeData.authRequired && !this.props.authState.isLoggedIn ? _LOGIN_ROUTE.exact : routeData.exact}
                path={routeData.authRequired && !this.props.authState.isLoggedIn ? _LOGIN_ROUTE.path : routeData.path}
                component={routeData.authRequired && !this.props.authState.isLoggedIn ? _LOGIN_ROUTE.component : routeData.component}
            />
        );
    }

    componentDidMount() {
        setTitle(this.props.history.location.pathname, _ROUTES);

        // if (this.props.authState.pendingTNC) {
        //     this.props.history.location.pathname = WebUrl._URL_TERMS_AND_CONDITIONS;
        // }
    }

    componentWillMount() {
        this.props.history.listen(() => {
            setTitle(this.props.history.location.pathname, _ROUTES);
        });
    }

    /// <summary>
    /// Author: -
    /// </summary>
    filterMenuByPermission() {
        var filteredRoutes = _ROUTES.filter(route => {
            return ((route.role == undefined ||
                route.role.filter(i => i == this.props.authState.userData.userRoleId).length != 0) ||
                (this.props.authState.isLoggedIn == false && route.path == this.props.history.location.pathname)
            );
        });

        if (this.props.authState.userData.accessString !== undefined) {
            var userPermissionData = this.props.authState.userData.accessString.split(";");
            var permissionArrKey = [];

            for (var i = 0; i < userPermissionData.length; i++) {
                var keyValuePermission = userPermissionData[i].split(":");

                if (keyValuePermission.length == 2) {
                    var accessLevel = keyValuePermission[1].toString();

                    if (accessLevel.includes(AccessRight._VIEW_PERMISSION)) {
                        permissionArrKey.push({ activity: keyValuePermission[0], accessLevel: accessLevel.split("") });
                    }
                }
            }

            filteredRoutes = filteredRoutes.filter(item => {
                let permission = item.permission;

                if (permission !== undefined) {
                    return permissionArrKey.some(i => i.activity == permission.activity && i.accessLevel.some(i => permission.accessLevel.includes(i)));
                }
                else {
                    return true;
                }
            });
        }

        this.state.routes = filteredRoutes;
    }

    render() {
        this.filterMenuByPermission();

        return (
            <PageSettings.Consumer>
                {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
                    <div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass} style={{ paddingLeft: '270px'}}>
                        {this.state.routes.map((route, index) => (
                            this.PrivateRoute(index, route)
                        ))}
                    </div>
                )}
            </PageSettings.Consumer>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(
    mapStateToProps,
    null
)(withRouter(Content));