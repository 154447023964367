import { WebUrl, AccessRight, Role, TemporaryDisableFor } from "../../util/Constant";

/// <summary>
/// Author: -
/// </summary>
const Menu = [
    {
        path: WebUrl._URL_DASHBOARD, title: 'HOME', isDummy: false,
    },
    {
        path: WebUrl._URL_ACCOUNT_MANAGEMENT, title: 'ACCOUNTS', isDummy: false, permission: { activity: AccessRight._DOWNLINE_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }
    },
    {
        title: 'BET', isDummy: true, permission: { activity: AccessRight._BET_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        children: [
            { path: WebUrl._URL_WEB_ORDER, title: 'WEB_ORDER' },
            { path: WebUrl._URL_WEB_ORDER_TWO, title: 'WEB_ORDER' + '(2)' },
            { path: WebUrl._URL_EXPRESS_BETTING, title: 'EXPRESS_BETTING' }
        ]
    },
    {
        title: 'HISTORY', isDummy: true, permission: { activity: AccessRight._INBOX_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        children: [
            { path: WebUrl._URL_INBOX, title: 'TICKET' },
            { path: WebUrl._URL_AUTO_TICKET, title: 'AUTO_TICKET' }
        ]
    },
    {
        title: 'PAYMENT', isDummy: true, permission: { activity: AccessRight._PAYMENT_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        children: [
            { path: WebUrl._URL_PAYMENT, title: 'PAYMENT' }
        ]
    },
    {
        title: 'REPORTS', isDummy: true,
        children: [
            { path: WebUrl._URL_STAKE_HOLDER_REPORT, title: 'STAKEHOLDER_REPORT', permission: { activity: AccessRight._STAKE_HOLDER_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_INBOX, title: 'INBOX', isDummy: false, permission: { activity: AccessRight._INBOX_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            //{ path: WebUrl._URL_WIN_LOSS_REPORT, title: 'WINLOSS_REPORT', permission: { activity: AccessRight._PROFIT_LOSS_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_WIN_LOSS_REPORT_SIMPLE, title: 'WINLOSS_REPORT', permission: { activity: AccessRight._PROFIT_LOSS_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_WIN_LOSS_3_SECTION_REPORT, title: 'WINLOSS_REPORT_2', permission: { activity: AccessRight._PROFIT_LOSS_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_SALES_STATISTIC_REPORT, title: 'SALES_STATISTIC_REPORT', permission: { activity: AccessRight._GROUP_SALES_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_SALES_SUMMARY_REPORT, title: 'SALES_SUMMARY_REPORT', permission: { activity: AccessRight._DAILY_SALES_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_PAYOUT_REPORT, title: 'PAYOUT_REPORT', permission: { activity: AccessRight._PAYOUT_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_PAYOUT_STATISTIC_REPORT, title: 'PAYOUT_STATISTIC_REPORT', permission: { activity: AccessRight._PAYOUT_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_PAYOUT_TABLE_REPORT, title: 'PAYOUT_TABLE_REPORT', permission: { activity: AccessRight._PAYOUT_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_MAP_REPORT, title: 'MAP_REPORT', permission: { activity: AccessRight._MAP_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_WINNING_REPORT, title: 'WINNING_REPORT', permission: { activity: AccessRight._WINNING_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
        ]
    },
    // {
    //     title: 'NUMBER_DICTIONARY', path: WebUrl._URL_NUMBER_MEANING,  isDummy: false
    // },
    { 
        title: 'RESULTS', path: WebUrl._URL_RESULT, role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } 
    },
    {
        title: 'ADMIN', isDummy: true, role: [Role._SUPER_ADMIN], permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        children: [
            { path: WebUrl._URL_POOL_SCHEDULE, title: 'DRAW_DATE' },
            { path: WebUrl._URL_CURRENCY, title: 'CURRENCY' },
            { path: WebUrl._URL_CUTOFF_TIME, title: 'CUTOFF_TIME' },
            { path: WebUrl._URL_MANAGE_PACKAGE, title: 'MASTER_PACKAGES' },
            { path: WebUrl._URL_COMPANY_MANAGEMENT, title: 'MANAGE_COMPANY', permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }, state: { roleId: Role._COMPANY } },
            { path: WebUrl._URL_ANNOUNCEMENT, title: 'ANNOUNCEMENT' },
            { path: WebUrl._URL_SYSTEM_SETTING, title: 'SYSTEM_SETTING' },
            { path: WebUrl._URL_ADMIN_TOOL, title: 'ADMIN_TOOL' },
            { path: WebUrl._URL_API_LOG, title: 'API_LOG' },
            { path: WebUrl._URL_API_REQUEST_LOG, title: 'API_REQUEST_LOG' },
            { path: WebUrl._URL_RUN_MAP_REPORT, title: 'RUN_MAP_REPORT', role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._MAP_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_RUN_MAP_REPORT_AS_COM, title: 'RUN_MAP_REPORT_COM', role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._MAP_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }, state: { asCom: true }},
            // { path: WebUrl._URL_PLAYER_TRANSACTION_REPORT, title: 'PLAYER_TRANSACTION_REPORT', role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }},
            { path: WebUrl._URL_UNPOST_PAGE, title: 'POSTING_UNPOST_PAGE', role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_POSTING_DISTRIBUTION_SUMMARY, title: 'POSTING_DISTRIBUTION_SUMMARY', role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
        ]
    },
    {
        title: 'DISTRIBUTOR', isDummy: true, role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._MAP_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        children: [
            { path: WebUrl._URL_RUN_MAP_REPORT_AS_COM, title: 'RUN_MAP_REPORT_COM', role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._MAP_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }, state: { asCom: true } },
            // { path: WebUrl._URL_PLAYER_TRANSACTION_REPORT, title: 'PLAYER_TRANSACTION_REPORT', role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_UNPOST_PAGE, title: 'POSTING_UNPOST_PAGE', role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
            { path: WebUrl._URL_POSTING_DISTRIBUTION_SUMMARY, title: 'POSTING_DISTRIBUTION_SUMMARY', role: [Role._SUPER_ADMIN, Role._COMPANY], permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] } },
        ]
    },
    {
        title: 'PACKAGE_LIST', path: WebUrl._URL_MANAGE_PACKAGE, role: [Role._HOUSE, Role._MULTILEVEL, Role._SHAREHOLDER, Role._ADMIN, Role._SUPER_SENIOR, Role._SENIOR, Role._MASTER, Role._AGENT], permission: { activity: AccessRight._PACKAGE_LIST_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }
    },
    {
        title: 'HELP', path: WebUrl._URL_HELP, isDummy: false
    },
]

export default Menu;
