import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { stringIsNullOrEmpty, showMessage, navigateTo } from '../../util/Util'
import { AccessRight, WebUrl, ApiKey, ApiUrl, AlertTypes, ValidationPattern, Role } from "../../util/Constant";
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import Menus from '../top-menu/menu.jsx';
import { performLogout } from '../../redux/AuthAction';
import CreditLimit from '../custom/CreditLimit';
import DropdownLanguage from './dropdown/language.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/custom/Input';
import { Link } from 'react-router-dom';

/// <summary>   
/// Author: -
/// </summary>
const Header = props => {
    const { t } = useTranslation();
    const _userData = useSelector(state => state.authState.userData);
    const [filteredMenu, setFilteredMenu] = useState([]);
    const [filteredProfileMenu, setFilteredProfileMenu] = useState([]);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const { register, handleSubmit, watch, setValue, control, errors } = useForm();
    const ProfileMenu = [
        { path: WebUrl._URL_MANAGE_PROFILE, title: 'MANAGE_PROFILE', mainAccountOnly: true },
        {
            title: 'EDIT_FIGHT_LIMIT', onClick: () => { navigateTo(_history, `${WebUrl._URL_FIGHT_LIMIT}?user=${_userData.userId}&name=${_userData.username}`); },
            onClick: () => {
                var width = 1000;
                var height = 800;
                var left = (window.screen.width / 2) - (width / 2);
                var top = (window.screen.height / 2) - (height / 2);

                window.open(`${WebUrl._URL_FIGHT_LIMIT}?user=${_userData.userId}&name=${_userData.username}`, `Fight Limit`, `directories=no,location=no,width=${width},height=${height},left=${left},top=${top}`);
            },
            mainAccountOnly: true, role: [Role._HOUSE, Role._MULTILEVEL, Role._AGENT, Role._SUPER_SENIOR, Role._SENIOR, Role._MASTER, Role._COMPANY, Role._SHAREHOLDER, Role._ADMIN],
            permission: { activity: AccessRight._FIGHT_LIMIT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }
        },
        {
            path: WebUrl._URL_PACKAGE_DETAIL, title: 'RED_PACKAGE_SETTINGS',
            mainAccountOnly: true, role: [Role._HOUSE, Role._MULTILEVEL, Role._AGENT, Role._SUPER_SENIOR, Role._SENIOR, Role._MASTER, Role._COMPANY, Role._SHAREHOLDER, Role._ADMIN],
            state: { isRedPackage: true }
        },
        { title: 'CHANGE_PASSWORD', onClick: () => { setShowChangePasswordModal(true); } },
    ]
    var _dispatch = useDispatch();
    var _history = useHistory();

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(_userData.accessString)) {
            filterMenuByPermission();
            filterProfileMenuByPermission();
        }
    }, [_userData])

    /// <summary>
    /// Author: -
    /// </summary>
    const filterMenuByPermission = () => {
        var menuToUse = JSON.parse(JSON.stringify(Menus));
        var userPermissionData = _userData.accessString.split(";");
        var permissionArrKey = [];

        for (var i = 0; i < userPermissionData.length; i++) {
            var keyValuePermission = userPermissionData[i].split(":");

            if (keyValuePermission.length == 2) {
                var accessLevel = keyValuePermission[1].toString();

                if (accessLevel.includes(AccessRight._VIEW_PERMISSION)) {
                    permissionArrKey.push({ activity: keyValuePermission[0], accessLevel: accessLevel.split("") });
                }
            }
        }

        //first filter away non use children
        for (var i = 0; i < menuToUse.length; i++) {
            if (menuToUse[i].children) {
                menuToUse[i].children = menuToUse[i].children.filter(item => {
                    let permission = item['permission'];
                    let role = item['role'];
                    let hasPermission = true;

                    if (permission !== undefined) {
                        hasPermission = permissionArrKey.some(i => i.activity == permission.activity && i.accessLevel.some(i => permission.accessLevel.includes(i)));
                    }

                    if (hasPermission && role !== undefined) {
                        hasPermission = role.some(i => i == _userData.userRoleId);
                    }

                    return hasPermission;
                });
            }
        }

        //secondly filter away parent
        menuToUse = menuToUse.filter(item => {
            if ((!item.children || item.children.length == 0) && item.isDummy) {
                return false;
            }

            let permission = item.permission;
            let role = item.role;

            let hasPermission = true;

            if (permission !== undefined) {
                hasPermission = permissionArrKey.some(i => i.activity == permission.activity && i.accessLevel.some(i => permission.accessLevel.includes(i)));
            }

            if (hasPermission && role !== undefined) {
                hasPermission = role.some(i => i == _userData.userRoleId);
            }

            return hasPermission;
        });

        setFilteredMenu(menuToUse);
    }

    /// <summary>
    /// Author: -
    /// </summary>
    function renderChildMenu(children, parentIndex) {
        return (
            children !== undefined &&
            <>
                <b className="caret"></b>
                <div className="dropdown-menu">
                    {
                        children.map((childMenu, index) => (
                            childMenu.isDummy ?
                                <button key={index} className="dropdown-item"><span className="pr-1">{`${parentIndex}.${index + 1}`}</span>{t(childMenu.title)}</button> :
                                <NavLink key={index} className="dropdown-item"
                                    to={{
                                        pathname: childMenu.path,
                                        state: childMenu.state
                                    }}>
                                    <span>
                                        <span className="pr-1">{`${parentIndex}.${index + 1}`}</span>
                                        {t(childMenu.title)}
                                    </span>
                                </NavLink>
                        ))
                    }
                </div>
            </>
        )
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const filterProfileMenuByPermission = () => {
        var menuToUse = ProfileMenu;
        var userPermissionData = _userData.accessString.split(";");
        var permissionArrKey = [];

        for (var i = 0; i < userPermissionData.length; i++) {
            var keyValuePermission = userPermissionData[i].split(":");

            if (keyValuePermission.length == 2) {
                var accessLevel = keyValuePermission[1].toString();

                if (accessLevel.includes(AccessRight._VIEW_PERMISSION)) {
                    permissionArrKey.push({ activity: keyValuePermission[0], accessLevel: accessLevel.split("") });
                }
            }
        }

        menuToUse = menuToUse.filter(item => {
            if ((!item.children || item.children.length == 0) && item.isDummy) {
                return false;
            }

            let permission = item.permission;
            let role = item.role;
            let mainAccountOnly = item.mainAccountOnly;
            let hasPermission = true;

            if (permission !== undefined) {
                hasPermission = permissionArrKey.some(i => i.activity == permission.activity && i.accessLevel.some(i => permission.accessLevel.includes(i)));
            }

            if (hasPermission && role !== undefined) {
                hasPermission = role.some(i => i == _userData.userRoleId);
            }

            if (hasPermission && mainAccountOnly !== undefined && mainAccountOnly) {
                hasPermission = stringIsNullOrEmpty(_userData.mainAccount);
            }

            return hasPermission;
        });

        setFilteredProfileMenu(menuToUse);
    }

    /// <summary>
    /// Author: -
    /// </summary>
    function logoutHandler() {
        showMessage({
            type: AlertTypes._WARNING,
            content: t("PROCEED_LOGOUT_ALERT"),
            showCancel: true,
            confirmBtnText: t("LOGOUT"),
            onConfirm: () => {
                _dispatch(performLogout());
            }
        });
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const onChangePassword = async (data, e) => {
        if (data.password != data.confirmPassword) {
            showMessage({ type: AlertTypes._ERROR, content: t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY") });
            return;
        }

        var responseJson = await ApiEngine.post(`${ApiUrl._API_CHANGE_PASSWORD}?newPassword=${data.password}&oldPassword=${data.oldPassword}`);

        showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setShowChangePasswordModal(false);
                }
            }
        });
    }

    return (
        <>
            <div id="header" className="header navbar-default">
                <div className="header-container">
                    <div className="header-menu">
                        <div className="header-nav">
                            <div className=" collapse navbar-collapse" id="navbar-collapse">
                                <ul className="nav">
                                    {
                                        filteredMenu.map((menu, parentIndex) => {
                                            const index = parentIndex + 1;

                                            return (_userData.userId != menu.temporaryDisableFor && < li key={index} >
                                                {
                                                    menu.isDummy ?
                                                        <div className="dropdown-item">
                                                            <span>{index}.</span>
                                                            {t(menu.title)}
                                                            {
                                                                renderChildMenu(menu.children, index)
                                                            }
                                                        </div> :
                                                        <NavLink to={{
                                                            pathname: menu.path,
                                                            state: menu.state
                                                        }} className="dropdown-item">
                                                            <span>{index}.</span>
                                                            {t(menu.title)}
                                                            {
                                                                renderChildMenu(menu.children, index)
                                                            }
                                                        </NavLink>
                                                }
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="header-user">
                            <ul className="nav">
                                <li>
                                    <div className="dropdown-item user-detail">
                                        <img src={require(`../../assets/img/svg/${process.env.REACT_APP_ASSET_PATH}user.svg`)} />
                                        <div className="header-username">{_userData?.username}</div>
                                        <b className="caret"></b>
                                        <div className="dropdown-menu">
                                            {
                                                filteredProfileMenu.map((menu, index) =>
                                                    <>
                                                        {
                                                            !menu.path ?
                                                                <div className="dropdown-item" onClick={() => {
                                                                    if (menu.onClick) {
                                                                        menu.onClick();
                                                                    }
                                                                }}>{t(menu.title)}</div> :
                                                                <NavLink tag={Link} key={index} className="dropdown-item"
                                                                    to={{
                                                                        pathname: menu.path,
                                                                        state: menu.state
                                                                    }}>
                                                                    {t(menu.title)}
                                                                </NavLink>
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <button onClick={() => logoutHandler()} className="btn logout-btn"><img src={require(`../../assets/img/svg/${process.env.REACT_APP_ASSET_PATH}logout-off.svg`)} /></button>
                        </div>
                    </div>
                    <CreditLimit />
                    <DropdownLanguage />
                </div>
            </div>
            <Modal isOpen={showChangePasswordModal}>
                <form onSubmit={handleSubmit(onChangePassword)}>
                    <ModalHeader>
                        <h4>{t('CHANGE_PASSWORD')}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group row m-b-15">
                            <div className="col-lg-12">
                                <Controller
                                    control={control}
                                    name="oldPassword"
                                    render={({ onChange, value, name }) => (
                                        <Input
                                            name={name}
                                            value={value}
                                            onChange={value => {
                                                onChange(value);
                                            }}
                                            label={`${t("OLD_PASSWORD")}*`}
                                            placeholder={`${t("OLD_PASSWORD")}`}
                                            type="password"
                                            errors={errors}
                                        />
                                    )}
                                    rules={{ required: true }}
                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-15">
                            <div className="col-md-12">
                                <Controller
                                    control={control}
                                    name="password"
                                    render={({ onChange, value, name }) => (
                                        <Input
                                            name={name}
                                            value={value}
                                            onChange={value => {
                                                onChange(value);
                                            }}
                                            label={`${t("PASSWORD")}*`}
                                            placeholder={`${t("PASSWORD")}`}
                                            type="password"
                                            errors={errors}
                                        />
                                    )}
                                    rules={{ required: true }}
                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-15">
                            <div className="col-lg-12">
                                <Controller
                                    control={control}
                                    name="confirmPassword"
                                    render={({ onChange, value, name }) => (
                                        <Input
                                            name={name}
                                            value={value}
                                            onChange={value => {
                                                onChange(value);
                                            }}
                                            label={`${t("CONFIRM_PASSWORD")}*`}
                                            placeholder={`${t("CONFIRM_PASSWORD")}`}
                                            type="password"
                                            errors={errors}
                                        />
                                    )}
                                    rules={{ required: true }}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setShowChangePasswordModal(false); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default Header;
